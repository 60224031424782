import type { IManagerFormDataItem, INode } from '@manager'

export const useObjectVModel = <
  P extends object,
  K extends keyof P,
  Name extends string,
>(
  props: P,
  key: K,
  emit: (name: Name, ...args: any[]) => void,
  initialValue = false,
) => {
  const node = (props as any).node as INode
  const nodeName = node.name

  const defaultValue = {
    [nodeName]: {
      data: defaultDataValue(),
    },
  } as P[K]

  const clone = (source: any) => {
    if (!source[nodeName]?.data) {
      source[nodeName] = {
        data: defaultDataValue(),
      }
    }
    return source
  }

  const proxy = useVModel(props, key, emit, {
    passive: true,
    deep: true,
    defaultValue,
    clone,
  })
  const dataProxy = computed<IManagerFormDataItem[]>({
    get: () => (proxy.value as any)[nodeName].data,
    set: (v) => {
      ;(proxy.value as any)[nodeName].data = v
    },
  })

  return [proxy, dataProxy] as const

  function defaultDataValue() {
    return initialValue ? [{}] : []
  }
}
