function scrollIntoViewAndWait(element: HTMLElement) {
  return new Promise((resolve) => {
    document.addEventListener('scrollend', resolve, { once: true })

    element.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'center',
    })
  })
}

export const useScrollAndBlinkDivByID = async (id: string) => {
  await nextTick()
  const el = document.getElementById(id)

  if (el) {
    await scrollIntoViewAndWait(el)
    el.classList.add('blink-new-element')
    setTimeout(() => {
      el.classList.remove('blink-new-element')
    }, 1000)
  }
}
