import { nanoid } from 'nanoid'
import type { MaybeRef } from 'vue'
import { useScrollAndBlinkDivByID } from '~/composables/ScrollAndBlinkDivByID'
import { copyObjectWithDescriptors } from '@manager'

export type RepeaterItem<T extends Record<string, any>> = T & {
  __id: string
  __deleted: boolean
  objectReferenceId: string
}

export interface UseRepeaterOptions {
  min?: number
  max?: number
  scrollAndBlink?: boolean
}

const RESERVED_KEYS = ['__id', '__deleted', 'objectReferenceId']

export const useRepeater = <T extends Record<string, any>>(
  initialValue: MaybeRef<T[]> = [],
  options: UseRepeaterOptions = {},
) => {
  const { min = 0, max = Infinity, scrollAndBlink = true } = options

  const items = ref(initialValue) as Ref<RepeaterItem<T>[]>
  const filteredItems = computed(() =>
    items.value.filter((item) => !item.__deleted),
  )

  const canAdd = computed(() => filteredItems.value.length < max)
  const canRemove = computed(() => filteredItems.value.length > min)

  init(items.value)

  const add = (item: T = {} as T) => {
    if (filteredItems.value.length >= max) return

    appendId(item)
    items.value.push(item as RepeaterItem<T>)

    if (scrollAndBlink) {
      useScrollAndBlinkDivByID((item as RepeaterItem<T>).__id)
    }
  }

  const update = (id: string, item: Partial<T>) => {
    const originalItem = items.value.find((item) => item.__id === id)
    if (originalItem) {
      Object.assign(originalItem, item)
    }
  }

  const remove = (id: string) => {
    if (filteredItems.value.length <= min) return

    const item = items.value.find((item) => item.__id === id)
    if (!item) return

    if (item.objectReferenceId !== '0') {
      for (const key in item) {
        if (!RESERVED_KEYS.includes(key)) {
          delete item[key]
        }
      }
      item.__deleted = true
    } else {
      const index = items.value.indexOf(item)
      items.value.splice(index, 1)
    }
  }

  const getById = (id: string): RepeaterItem<T> | undefined => {
    return items.value.find((item) => item.__id === id) as
      | RepeaterItem<T>
      | undefined
  }

  const __stored = ref<RepeaterItem<T>[]>([]) as Ref<RepeaterItem<T>[]>
  onActivated(() => {
    // We only want to restore the data if all items are deleted and there are items to be restored.
    if (
      filteredItems.value.length === 0 &&
      __stored.value.filter((i) => i.__deleted === false).length > 0
    ) {
      items.value = __stored.value
    }
  })
  onDeactivated(() => {
    // Copy the items keeping the descriptors, and save them to be restored later.
    __stored.value = items.value.map((o) => copyObjectWithDescriptors(o))
    for (const item of items.value) {
      remove(item.__id)
    }
  })

  return {
    items,
    filteredItems,
    canAdd,
    canRemove,
    add,
    update,
    remove,
    getById,
  }

  function appendId(item: T): void {
    Object.defineProperties(item, {
      objectReferenceId: {
        enumerable: true,
        writable: true,
        value: item.objectReferenceId ?? '0',
      },
      __id: {
        enumerable: false,
        writable: true,
        value: item.__id ?? nanoid(),
      },
      __deleted: {
        enumerable: false,
        writable: true,
        value: false,
      },
    })
  }

  function init(items: T[]): void {
    items.forEach((item) => appendId(item))
  }
}
